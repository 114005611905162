$(function(){
  if ($("body").hasClass("theme-chemistanddruggist")) {
    setTimeout(function () {
      var Value = $("#industry").find('.selectivity-single-selected-item').data('item-id');
      var jobValue = $("#Jobtitle").find('.selectivity-single-selected-item').data('item-id');
      if (Value != undefined) {
        //document.getElementById('page-registration__pharmacy-type').style.display = Value == 30 ? 'block' : 'none';
        $('#page-registration__pharmacy-type').css('display', Value == 30 ? 'block' : 'none');
        fillJobTitleDropdown(Value);
      }
      if (jobValue != undefined) {
        var idTotest = ",8,9,10,11,12,82,84,"
        var patt = new RegExp(","+jobValue+",")
        if(patt.test(idTotest)) {
          $('#pharma-add-show-hide').show();
        } else {
          $('#pharma-add-show-hide').hide();
        }
      }
    }, 1500);
  }
});

$(document).on('change', '#industry', function () {
  var Value = $(this).find('.selectivity-single-selected-item').data('item-id');
  //document.getElementById('page-registration__pharmacy-type').style.display = Value == 30 ? 'block' : 'none';
  $('#page-registration__pharmacy-type').css('display', Value == 30 ? 'block' : 'none');
  $("#page-registration__pharmacy-type input[type=checkbox]").each(function(){
    if(Value == 30) {
      $(this).removeAttr('checked');
	  }
  });
  fillJobTitleDropdown(Value);
});

$(document).on('change', '#Jobtitle', function () {
  var jobValue = $(this).find('.selectivity-single-selected-item').data('item-id');
  var idTotest = ",8,9,10,11,12,82,84,"
  var patt = new RegExp(","+jobValue+",")
  if (patt.test(idTotest)) {
	  $('#pharma-add-show-hide').show();
  } else {
	  $('#pharma-add-show-hide').hide();
  }
});

$(".find-address").click(function () {
  $(".invalid-pincode-validation").hide();
  var pinCode = $(".pincode-value").val();
  if (pinCode != '') {
    $(".js-form-error-pincode-value").hide();
    $.ajax({
    type: "GET",
    url: "https://api.getaddress.io/find/"+pinCode + "?api-key=2AlzEYEvPkmFh2lLbhhN2Q11060&sort=true",
    dataType: "json",
    success: function success(data) {
      $(".invalid-pincode-validation").hide();
      var address = [];
      $('#postcodeAddressListDropdown').selectivity('clear');
      $.each(data.addresses, function (key, item) {
        var arrItem = item.split(",");
        var newItem = "";
        $.each(arrItem, function(i,d){
          if(d != null && d != undefined && $.trim(d).length > 0){
            newItem = newItem + d + ",";
          }
        });
        address.push({id:item,text:newItem});
      });
      $('#postcodeAddressListDropdown').selectivity({ items: address });
      $('#postcodeAddressListDropdown').selectivity('data', { id: '0',text: '-Click to select address-' })
      $("#page-registration__find-address").show();
    }, error: function(data) {
        var err = JSON.parse(data.response);
        $(".invalid-pincode-validation").show().html(err.Message);
        $("#page-registration__find-address").hide();
        $("#AddressLine1").val("");
        $("#AddressLine2").val("");
        $("#AddressLine3").val("");
        $("#City").val("");
        $("#Postcode").val("");
        $(".hidden-address-fields").hide();
      }
    });
  } else {
    $(".js-form-error-pincode-value").show();
  }
  
});

$(document).on('change', '#postcodeAddressListDropdown', function () {
  var value = $(this).find('.selectivity-single-selected-item').data('item-id');
  if(value != 0 && value != undefined) {
    var valueSplit = value.split(",");
    if($.trim(valueSplit[0])) $("#AddressLine1").val(valueSplit[0]);
    if($.trim(valueSplit[1])) $("#AddressLine2").val(valueSplit[1]);
    if($.trim(valueSplit[2])) $("#AddressLine3").val(valueSplit[2]);
    if($.trim(valueSplit[5])) $("#City").val(valueSplit[5]);
    $("#Postcode").val($(".pincode-value").val());
    $(".hidden-address-fields").show();
  } else{
      $("#AddressLine1").val("");
      $("#AddressLine2").val("");
      $("#AddressLine3").val("");
      $("#City").val("");
      $("#Postcode").val("");
      $(".hidden-address-fields").hide();
  }
});

function fillJobTitleDropdown(industryId) {
  if ($.trim(industryId) != '') {
    $.ajax({
      type: "GET",
      url: "/Account/api/AjaxApi/GetIndustryJobList?industryId=" + industryId,
      dataType: "json",
      success: function success(data) {
        var newData = [];
		    var newChild=[];
        var defaultSelectection = { id: " ", text: "- Click to select job title * -" };
        newData.push(defaultSelectection);
        if(industryId != 30) {
          $.each(data.items, function (key, item) {
            $.each(item.children, function (akey, aitem) {
              newData.push({ id: aitem.id, text: aitem.name });
              //newData.push({ id: aitem.key, text: aitem.value });
            });
          });
        } else {
          $.each(data.items, function (key, item) {
            newChild=[];
            $.each(item.children, function (akey, aitem) {
              newChild.push({ id: aitem.id, text: aitem.name }); 
              //newChild.push({ id: aitem.key, text: aitem.value });  
            });
            newData.push({ text: item.text, children:newChild });
          });			
        }
        if (data != null) {
          $('#Jobtitle').selectivity({ items: newData });
          $('#Jobtitle').selectivity('clear');
          $('#Jobtitle').selectivity('value', data.selectedJob == null ? " ":parseInt(data.selectedJob));
          //$('#Jobtitle').selectivity('value', data.selectedJob == null ? " ":data.selectedJob);
        }
      },
      error: function error(data) {
        console.log("error");
      }
    });
  } else {
    $('#Jobtitle').selectivity('clear');
    $('#Jobtitle').selectivity({ items: [] });
    $('#Jobtitle').selectivity('data', { id: '0', text: '- Click to select job title * -' });
  }
}

$(".page-account__submit-wrapper button[type='submit']").click(function() {
  var industryName = $("#industry").find('.selectivity-single-selected-item').data('item-id');
  //document.getElementById('page-registration__pharmacy-type').style.display = industryName == 30 ? 'block' : 'none';
  $('#page-registration__pharmacy-type').css('display', industryName == 30 ? 'block' : 'none');
  $(".hidden-address-fields").show();
});