$(function(){
  if ($("body").hasClass("theme-chemistanddruggist")) {
    $(".article-body-content").each(function(){
      var sponsoredColor = $(this).data("sponsored-color");
      if(sponsoredColor) {
        $(this).find(".article-body-content > h1").css({"color":sponsoredColor});
        $(this).find(".article-body-content > h3").css({"color":sponsoredColor});
        $(this).find(".article-body-content .root > h2").css({"color":sponsoredColor});
        $(this).find(".article-body-content .root > h3:nth-of-type(1)").css({"color":sponsoredColor});
      }
    });
    $(".longread-article").each(function () {
      var sponsoredColor = $(this).data("sponsored-color");
      if (sponsoredColor) {
        $(this).find(".lra-header > h2").css({ "color": sponsoredColor });
        $(this).find(".lra-header > h3").css({ "color": sponsoredColor });
        $(this).find(".longread-article .root > h2").css({ "color": sponsoredColor });
        $(this).find(".longread-article .root > h3:nth-of-type(1)").css({ "color": sponsoredColor });
      }
    });
    // This snippet ensures that the return url always exist in the hidden field as in some case, the backend code did not work
    $.urlParam = function (name) {
      var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.search);
      return results !== null ? results[1] || 0 : false;
    };
    var returnUrl = $.urlParam('returnUrl');
    var phValue = $('#returnUrlPh').attr('value');
    var decodedValue = decodeURIComponent(returnUrl);
    if(returnUrl == null || returnUrl == "" || returnUrl == undefined || returnUrl == false ) {
      returnUrl = "";
    }
    if(phValue == null || phValue == "" || phValue == undefined || phValue !== decodedValue) {
      $('#returnUrlPh').attr('value', returnUrl);
    }
    // article page accordian
    $(".accordian-div").each(function(){
      $(this).find("h3").click(function(){
        if ($(this).parent().hasClass("active")){
          $(this).parent().removeClass("active");
        } else {
          $(this).parent().addClass("active");
        }
      });
    });
    // Trigger Sitecore Goal For Promotional Component
    $(document).on('click', '.article-inline-promotional-sidebar .button--filled, #promotionalcomponent', function () {
      var goalId = $(this).attr('goalId');
      if (goalId != null && goalId != '')
        TriggerSitecoreGoal(goalId);
    });
    function TriggerSitecoreGoal(goalId) {
      $.ajax({
        type: "POST",
        url: '/api/sitecore/sitecoreanalytics/triggergoal',
        data: { strGoalId: goalId },
        success: function (data) {
          console.log(data);
        }
      });
    }
    // if sign box exist on article page then hiding the question section
    if($(".article-left-rail").find(".article-call-to-action").length > 0) {
      if($(".article-left-rail").find(".comment-wrapper").length > 0) {
        $(".article-left-rail").find(".comment-wrapper").hide();
      }
    }
  }
});

$(window).on('load', function() {
  if ($("body").hasClass("theme-chemistanddruggist")) {
    // if no ad present in longread article, hiding div and removing sticky ad for long read article
    $(".longread-article").find(".inline-related-content").each(function(){
      if($(this).find(".article-preview").hasClass("advertising--sticky")) {
        $(this).find(".article-preview").removeClass("advertising--sticky");
      }
      if($(this).find(".article-preview__ad").hasClass("hidden")) {
        $(this).hide();
      }
    });
    // mpu1 and mpu3 ad hiding from mobile
    $(".article-right-rail").find(".article-preview").each(function(){
      var $articlePreview = $(this),
          dataAdAttr = $articlePreview.find(".article-preview__ad > div").attr("data-ad-zone");
      if (dataAdAttr === 'mpu1' || dataAdAttr === 'mpu2') {
        $articlePreview.addClass('mpu-hide-mobile');
      }
    });
  }
});