﻿if ($("body").hasClass("theme-lloydslist")) {
    var vessel_size_li, vessel_x, company_size_li, company_x, place_size_li, place_x;
    $(document).ready(function () {

        vessel_size_li = $(".seasearchitemVessel li").size();
        company_size_li = $(".seasearchitemCompany li").size();
        place_size_li = $(".seasearchitemport li").size();
        vessel_x = $("#vesselitemscount").val();
        company_x = $("#companyitemscount").val();
        place_x = $("#placeitemscount").val();
        // vessel
        $('.seasearchitemVessel li').slice(0, vessel_x).show();
        $('.seasearchitemVessel li').slice(vessel_x, vessel_size_li).hide();
        $(".more--vessel").hide();
        $(".less--vessel").hide();
        if (vessel_size_li > vessel_x) {
            $(".more--vessel").show();
            $(".less--vessel").hide();
            // $(".seasearchitemport").append('<li class="article-topics__li more">See All</li>');
        }

        $(document).on('click', '.more--vessel,.less--vessel', function () {
            // x = (x + 5 <= size_li) ? x + 5 : size_li;


            if (vessel_x == vessel_size_li) {
                vessel_x = $("#vesselitemscount").val();
                $('.seasearchitemVessel li').slice(0, vessel_x).show();
                $('.seasearchitemVessel li').slice(vessel_x, vessel_size_li).hide();
                //$(this).text('See All');
                $(".more--vessel").show();
                $(".less--vessel").hide();
            }
            else {
                vessel_x = vessel_size_li;
                $('.seasearchitemVessel li').slice(0, vessel_size_li).show();
                $(".less--vessel").show();
                $(".more--vessel").hide();
                // $(this).text('See Less');
            }

        });

        // company
        $('.seasearchitemCompany li').slice(0, company_x).show();
        $('.seasearchitemCompany li').slice(company_x, company_size_li).hide();

        $(".more--company").hide();
        $(".less--company").hide();
        if (company_size_li > company_x) {
            $(".more--company").show();
            $(".less--company").hide();
            // $(".seasearchitemport").append('<li class="article-topics__li more">See All</li>');
        }

        $(document).on('click', '.more--company,.less--company', function () {
            // x = (x + 5 <= size_li) ? x + 5 : size_li;


            if (company_x == company_size_li) {
                company_x = $("#companyitemscount").val();
                $('.seasearchitemCompany li').slice(0, company_x).show();
                $('.seasearchitemCompany li').slice(company_x, company_size_li).hide();
                //$(this).text('See All');
                $(".more--company").show();
                $(".less--company").hide();
            }
            else {
                company_x = company_size_li;
                $('.seasearchitemCompany li').slice(0, company_size_li).show();
                $(".less--company").show();
                $(".more--company").hide();
                // $(this).text('See Less');
            }

        });

       

        // Port
        $('.seasearchitemport li').slice(0, place_x).show();
        $('.seasearchitemport li').slice(place_x, place_size_li).hide();
        $(".more--place").hide();
        $(".less--place").hide();
        if (place_size_li > place_x) {
            $(".more--place").show();
            $(".less--place").hide();
            // $(".seasearchitemport").append('<li class="article-topics__li more">See All</li>');
        }

        $(document).on('click', '.more--place,.less--place', function () {
            // x = (x + 5 <= size_li) ? x + 5 : size_li;


            if (place_x == place_size_li) {
                place_x = $("#placeitemscount").val();
                $('.seasearchitemport li').slice(0, place_x).show();
                $('.seasearchitemport li').slice(place_x, place_size_li).hide();
                //$(this).text('See All');
                $(".more--place").show();
                $(".less--place").hide();
            }
            else {
                place_x = place_size_li;
                $('.seasearchitemport li').slice(0, place_size_li).show();
                $(".less--place").show();
                $(".more--place").hide();
                // $(this).text('See Less');
            }

        });

    });
}