(function (argument) {
	var LatestILaw = {
		RenderLinks: function RenderLinks(data, Parent) {
			var latestILawData = data,
				Html = "";

			latestILawData.sort((a, b) => {
				return new Date(b.date) - new Date(a.date); // descending
			});

			for (var key in latestILawData) {
				var formattedDate = "";
				if (latestILawData[key].date) {
					var dt = new Date(latestILawData[key].date)
					formattedDate = `${dt.getFullYear()}-${dt.getMonth()}-${dt.getDate()} ${dt.toLocaleTimeString()}`
				}
				 Html += '<li class="article-topics__li"><a data-info="{ "event_name": "maritime_product_link", "click_through_source": "' + analytics_data.page_name + '", "source_page_type":"' + analytics_data.page_type +'", "click_through_destination": "' + latestILawData[key].title + '" }" href="' + latestILawData[key].link + '&utm_source=LloydsList"><strong>' + latestILawData[key].title + '</strong> - ' + formattedDate + ' </a></li>';
			}
			Parent.find('ul').append(Html);
		},
		init: function (data, parent) {
			this.RenderLinks(data, parent);
		}
	};

	if ($('.lloyd-ilaw-related-links').length > 0) {
		if (typeof window.jsonLatestILawData !== 'undefined' && typeof window.jsonLatestILawData !== 'string') {
			LatestILaw.init(window.jsonLatestILawData, $('.lloyd-ilaw-related-links'));
		} else {
			$('.lloyd-ilaw-related-links').append('<div class="alert-error" style="display: block;"><svg class="alert__icon"><use xmlns:xlink=http://www.w3.org/1999/xlink" xlink:href="/dist/img/svg-sprite.svg#alert"></use></svg><p class="page-account-contact__error">' + $('#hdnErrormessage').val() + '</p></div>');
		}
	}
})();